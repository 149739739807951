import React from 'react';

import Gallery from 'react-grid-gallery';

import IMG2011icon1 from '../assets/img/history/icon/2011/1.JPG';
import IMG2011icon2 from '../assets/img/history/icon/2011/2.JPG';
import IMG2011icon3 from '../assets/img/history/icon/2011/3.JPG';
import IMG2011icon4 from '../assets/img/history/icon/2011/4.JPG';
import IMG2011icon5 from '../assets/img/history/icon/2011/5.JPG';
import IMG2012icon1 from '../assets/img/history/icon/2012/1.jpg';
import IMG2012icon2 from '../assets/img/history/icon/2012/2.jpg';
import IMG2012icon3 from '../assets/img/history/icon/2012/3.jpg';
import IMG2013icon1 from '../assets/img/history/icon/2013/1.JPG';
import IMG2013icon2 from '../assets/img/history/icon/2013/2.JPG';
import IMG2013icon3 from '../assets/img/history/icon/2013/3.JPG';
import IMG2014icon1 from '../assets/img/history/icon/2014/1.JPG';
import IMG2014icon2 from '../assets/img/history/icon/2014/2.JPG';
import IMG2014icon3 from '../assets/img/history/icon/2014/3.jpg';
import IMG2014icon4 from '../assets/img/history/icon/2014/4.jpg';
import IMG2015icon1 from '../assets/img/history/icon/2015/1.jpg';
import IMG2015icon2 from '../assets/img/history/icon/2015/2.jpg';
import IMG2015icon3 from '../assets/img/history/icon/2015/3.JPG';
import IMG2015icon4 from '../assets/img/history/icon/2015/4.JPG';
import IMG2016icon1 from '../assets/img/history/icon/2016/1.JPG';
import IMG2016icon2 from '../assets/img/history/icon/2016/2.JPG';
import IMG2016icon3 from '../assets/img/history/icon/2016/3.JPG';
import IMG2016icon4 from '../assets/img/history/icon/2016/4.JPG';
import IMG2017icon1 from '../assets/img/history/icon/2017/1.JPG';
import IMG2017icon2 from '../assets/img/history/icon/2017/2.JPG';
import IMG2017icon3 from '../assets/img/history/icon/2017/3.JPG';
import IMG2018icon1 from '../assets/img/history/icon/2018/1.JPG';
import IMG2018icon2 from '../assets/img/history/icon/2018/2.JPG';
import IMG2018icon3 from '../assets/img/history/icon/2018/3.JPG';
import IMG2019icon1 from '../assets/img/history/icon/2019/1.JPG';
import IMG2019icon2 from '../assets/img/history/icon/2019/2.JPG';
import IMG2019icon3 from '../assets/img/history/icon/2019/3.JPG';
import IMG2019icon4 from '../assets/img/history/icon/2019/4.JPG';
import IMG2019icon5 from '../assets/img/history/icon/2019/5.JPG';

import IMG2011full1 from '../assets/img/history/full/2011/1.JPG';
import IMG2011full2 from '../assets/img/history/full/2011/2.JPG';
import IMG2011full3 from '../assets/img/history/full/2011/3.JPG';
import IMG2011full4 from '../assets/img/history/full/2011/4.JPG';
import IMG2011full5 from '../assets/img/history/full/2011/5.JPG';
import IMG2012full1 from '../assets/img/history/full/2012/1.jpg';
import IMG2012full2 from '../assets/img/history/full/2012/2.jpg';
import IMG2012full3 from '../assets/img/history/full/2012/3.jpg';
import IMG2013full1 from '../assets/img/history/full/2013/1.JPG';
import IMG2013full2 from '../assets/img/history/full/2013/2.JPG';
import IMG2013full3 from '../assets/img/history/full/2013/3.JPG';
import IMG2014full1 from '../assets/img/history/full/2014/1.JPG';
import IMG2014full2 from '../assets/img/history/full/2014/2.JPG';
import IMG2014full3 from '../assets/img/history/full/2014/3.jpg';
import IMG2014full4 from '../assets/img/history/full/2014/4.jpg';
import IMG2015full1 from '../assets/img/history/full/2015/1.jpg';
import IMG2015full2 from '../assets/img/history/full/2015/2.jpg';
import IMG2015full3 from '../assets/img/history/full/2015/3.JPG';
import IMG2015full4 from '../assets/img/history/full/2015/4.JPG';
import IMG2016full1 from '../assets/img/history/full/2016/1.JPG';
import IMG2016full2 from '../assets/img/history/full/2016/2.JPG';
import IMG2016full3 from '../assets/img/history/full/2016/3.JPG';
import IMG2016full4 from '../assets/img/history/full/2016/4.JPG';
import IMG2017full1 from '../assets/img/history/full/2017/1.JPG';
import IMG2017full2 from '../assets/img/history/full/2017/2.JPG';
import IMG2017full3 from '../assets/img/history/full/2017/3.JPG';
import IMG2018full1 from '../assets/img/history/full/2018/1.JPG';
import IMG2018full2 from '../assets/img/history/full/2018/2.JPG';
import IMG2018full3 from '../assets/img/history/full/2018/3.JPG';
import IMG2019full1 from '../assets/img/history/full/2019/1.JPG';
import IMG2019full2 from '../assets/img/history/full/2019/2.JPG';
import IMG2019full3 from '../assets/img/history/full/2019/3.JPG';
import IMG2019full4 from '../assets/img/history/full/2019/4.JPG';
import IMG2019full5 from '../assets/img/history/full/2019/5.JPG';

const HistoryContent = () => {
	const IMAGES2011 = [
		{
			src: IMG2011full1,
			thumbnail: IMG2011icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2011full2,
			thumbnail: IMG2011icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2011full3,
			thumbnail: IMG2011icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2011full4,
			thumbnail: IMG2011icon4,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2011full5,
			thumbnail: IMG2011icon5,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	const IMAGES2012 = [
		{
			src: IMG2012full1,
			thumbnail: IMG2012icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2012full2,
			thumbnail: IMG2012icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2012full3,
			thumbnail: IMG2012icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	const IMAGES2013 = [
		{
			src: IMG2013full1,
			thumbnail: IMG2013icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2013full2,
			thumbnail: IMG2013icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2013full3,
			thumbnail: IMG2013icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	const IMAGES2014 = [
		{
			src: IMG2014full1,
			thumbnail: IMG2014icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2014full2,
			thumbnail: IMG2014icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2014full3,
			thumbnail: IMG2014icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2014full4,
			thumbnail: IMG2014icon4,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	const IMAGES2015 = [
		{
			src: IMG2015full1,
			thumbnail: IMG2015icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2015full2,
			thumbnail: IMG2015icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2015full3,
			thumbnail: IMG2015icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2015full4,
			thumbnail: IMG2015icon4,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	const IMAGES2016 = [
		{
			src: IMG2016full1,
			thumbnail: IMG2016icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2016full2,
			thumbnail: IMG2016icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2016full3,
			thumbnail: IMG2016icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2016full4,
			thumbnail: IMG2016icon4,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	const IMAGES2017 = [
		{
			src: IMG2017full1,
			thumbnail: IMG2017icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2017full2,
			thumbnail: IMG2017icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2017full3,
			thumbnail: IMG2017icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	const IMAGES2018 = [
		{
			src: IMG2018full1,
			thumbnail: IMG2018icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2018full2,
			thumbnail: IMG2018icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2018full3,
			thumbnail: IMG2018icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	const IMAGES2019 = [
		{
			src: IMG2019full1,
			thumbnail: IMG2019icon1,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2019full2,
			thumbnail: IMG2019icon2,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2019full3,
			thumbnail: IMG2019icon3,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2019full4,
			thumbnail: IMG2019icon4,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
		{
			src: IMG2019full5,
			thumbnail: IMG2019icon5,
			thumbnailWidth: 250,
			thumbnailHeight: 250,
		},
	];
	return (
		<div className='history'>
			<h2 className='history__title'>Poznaj naszą historię</h2>
			<div className='history__element'>
				<h3 className='element__year'>2011</h3>
				<div className='element__text'>
					Nasza historia rozpoczyna się w 2011r. Wieloletni nauczyciel fizyki p.
					Andrzej Płocharski zaprasza do współpracy p. Ignacego Rejmaka, aby
					wspólnie stworzyć przestrzeń dla młodzieży uzdolnionej w kierunku
					matematyki i fizyki. Organizują obóz naukowy. Poszukują wśród swoich
					uczniów takich, dla których te dyscypliny naukowe są ich miłością i
					bramą do przyszłości. Tak rozpoczyna się podróż, która trwa po dziś
					dzień. Pierwszy obóz nadaje kierunek dalszym działaniom. Pokazuje
					drogę, po której dumnie kroczyć będą następne pokolenia przyszłych
					naukowców. Jednak co najważniejsze opierają swój obóz na relacji.
					Relacji przyjacielskiej, mentorskiej, pozbawionej cech stereotypowego
					nauczania. Bez dzwonków, bez stresu, w atmosferze rodzinnych więzi.
					Dbając o każdy aspekt rozwoju młodych ludzi. Emocjonalny, duchowy,
					fizyczny i kompetencyjny.
				</div>
				<Gallery images={IMAGES2011} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__element'>
				<h3 className='element__year'>2012</h3>
				<div className='element__text'>
					Kiedy wracają do szkoły, do pierwszych dzwonków i sal lekcyjnych
					wiedzą już, że ich przygoda musi nabrać wiatru w żagle. Rozpoczynają
					projekt „Wyobraźnia ważniejsza od wiedzy”, który realizują jako
					innowację pedagogiczną przy współpracy Uniwersytetu Gdańskiego i
					pracowników naukowych. Młodzież w ciągu całego roku uczestniczy w
					dodatkowych zajęciach z matematyki, fizyki a także uczestniczy w
					wykładach popularnonaukowych. Aby zadbać o rozwój osobisty swoich
					uczniów zapraszają do współpracy pedagoga szkolnego, aby uzdolniony
					młody człowiek, był świadomy swojej wartości i pewności siebie.
				</div>
				<Gallery images={IMAGES2012} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__element'>
				<h3 className='element__year'>2013</h3>
				<div className='element__text'>
					Kolejne pokolenia wiedzą już, że ich udział w obozie to nagroda za
					ciężką całoroczną pracę. Wiedzą bowiem, że ich obozowa rodzina to
					przestrzeń niezwykłych relacji, które budują pomiędzy sobą a także z
					nauczycielami. Każdy poranek rozpoczynają zaprawą poranną, aby
					następnie każdego dnia poświęcić się nauce. Każdego dnia, dziewięć
					godzin z nauką i własnym rozwojem.{' '}
				</div>
				<Gallery images={IMAGES2013} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__element'>
				<h3 className='element__year'>2014</h3>
				<div className='element__text'>
					Pomiędzy zajęciami uczniowie korzystają z obecności swoich mentorów.
					Dyskutują pomiędzy sobą o nauce, filozofii, nurtujących ich
					problemach. Grają w siatkówkę, pływają w jeziorze, spędzają czas z
					dala od telefonów komórkowych, komputerów i tabletów. Nikt im jednak
					nie daje na to ograniczeń. Tu nie ma zakazów. Nie mają jednak na to
					czasu. Swój czas poświęcają na relacje. Każdy obóz to także jeden
					dzień całkowicie wolny od zajęć. Wówczas wcześnie rano jego uczestnicy
					szykują się na przygodę. Spływ kajakowy, podczas którego relacje,
					pokonywanie ograniczeń, dobry humor, zabawa stanowią elementy całego
					dnia. Po powrocie wspólnie siadają przy ognisku, aby wciąż cieszyć się
					swoim towarzystwem, wspierać się i być….zwyczajnie….TU i TERAZ.{' '}
				</div>
				<Gallery images={IMAGES2014} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__element'>
				<h3 className='element__year'>2015</h3>
				<div className='element__text'>
					Wieczorami, kiedy po kolacji zakończą się zajęcia nikt nie kieruje
					swych kroków do pokoju. Uczestnicy spotykają się, aby wspólnie
					posłuchać wykładów popularnonaukowych, rozegrać Wielki Turniej.
					Czasami siadają ze swoimi nauczycielami i rozmawiają. Liczą zadania,
					słuchają wskazówek. Są obecni, poza światem wirtualnym, w środku
					lasu….są na spotkaniu z nauką.{' '}
				</div>
				<Gallery images={IMAGES2015} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__element'>
				<h3 className='element__year'>2016</h3>
				<div className='element__text'>
					Zajęcia odbywają się czasami – kiedy pozwala na to pogoda – także na
					świeżym powietrzu. Pracownicy ośrodka nie mogą wyjść z podziwu, kiedy
					widzą młodych ludzi, w środku lata z zeszytami na pomoście, leżących
					na kocach, na trawie bacznie przyglądających się wzorom zapisywanym na
					tablicy. Mówią, że to niemożliwe, aby młodzi ludzie poświęcali swoje
					wakacje na naukę. To są jednak ludzie wyjątkowi, zakochani w nauce.
					Mają marzenia, aby być kiedyś naukowcem, programistą, robotykiem,
					architektem, matematykiem, fizykiem. Te obozy to dla nich trampolina
					do przyszłości. Ich przyszłości. Już widzą swoich starszych kolegów,
					absolwentów, ludzi, którzy tak jak oni poświęcali swój czas. Są na
					dobrych uczelniach, w szkołach średnich, które sami sobie wybrali, bo
					wiedzieli czego chcą. Widzą ich sukcesy w konkursach. Chcą być tacy
					jak oni. Chcą dojść daleko.{' '}
				</div>
				<Gallery images={IMAGES2016} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__element'>
				<h3 className='element__year'>2017</h3>
				<div className='element__text'>
					Uczestnicy obozów kontynuowali naukę na takich kierunkach jak:
					automatyka i robotyka, finanse i rachunkowość, ekonomia, matematyka,
					gospodarka przestrzenna, energetyka, oceanotechnika, kognitywistyka i
					wiele innych. Projekt dał podstawę naukową ponad 150 młodym ludziom
					uczestniczącym w nim na przestrzeni 9 lat. Rodzice uczniów
					uczestniczących w projekcie wydawali rekomendacje, w których pisali:
					„Jestem rodzicem trójki absolwentów Gimnazjum nr 1 w Lęborku. Żałuję,
					że mój najstarszy syn, który ukończył naukę w 2005 roku nie miał
					możliwości uczestnictwa w projekcie „Wyobraźnia ważniejsza od wiedzy”
					projekt wystartował kilka lat później. Cieszę się, że córka (ukończyła
					Gimnazjum w 2012 roku) i młodszy syn (ukończył Gimnazjum w 2014 roku),
					taką możliwość już mieli. Z perspektywy własnego doświadczenia
					życiowego wiem, jak ważnym jest spotkać na swojej drodze kogoś, kto
					zauważy i odkryje u nas talenty, zainspiruje do pracy nad sobą, pokaże
					różne drogi dojścia do celu, rozbudzi przekonanie i wiarę w swoje
					możliwości. Jest to szczególnie ważne jak się ma kilkanaście lat.
					Obserwując i oceniając od samego początku realizację projektu
					„Wyobraźnia ważniejsza od wiedzy” wiem, że moje młodsze dzieci miały
					dużo szczęścia, gdy w okresie problemów z samooceną, „obniżonego”
					autorytetu rodziców, przy jednocześnie sporym potencjale
					intelektualnym, zostały objęte troskliwą opieką fantastycznych
					nauczycieli. Ze zdumieniem obserwowałem, że uczestnictwo w licznych
					przedsięwzięciach wynikających z koncepcji projektu powoduje, że moje
					dzieci otworzyły się na otoczenie, nabrały pewności siebie, stawały
					się coraz bardziej odpowiedzialne, jednocześnie rozwijały swoje
					zainteresowania w naukach ścisłych, zdobywając laury w licznych
					konkursach. Rodzice zyskali w swoich dzieciach partnerów do dyskusji,
					którzy potrafią argumentować swoje stanowisko w sposób zaskakująco
					dojrzały. Jestem przekonany, że nie byłoby tych sukcesów bez
					poświęcenia i zaangażowania znakomitych nauczycieli, osobowości
					Gimnazjum nr 1 w Lęborku (…) - twórców projektu (…) Ich program
					wdrażany i rozwijany z ogromną determinacją, przy znakomitych
					relacjach z młodymi ludźmi, daje niesamowite efekty tak w sferze
					ogólnego rozwoju osobowości, jak również w nauce. Umiejętności, pasja
					i nieustający entuzjazm nauczycieli spowodował, że projektem
					zainteresowano młodzież, rodziców oraz nauczycieli akademickich z
					bogatym dorobkiem naukowym. Umiejętne połączenie wielu różnych
					dziedzin i form działania, to przykład profesjonalizmu twórców
					projektu.”{' '}
				</div>
				<Gallery images={IMAGES2017} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__element'>
				<h3 className='element__year'>2018</h3>
				<div className='element__text'>
					Wiek XXI, według opinii wielu naukowców i ekonomistów, to czas
					kapitału, którym będą wiedza i kreatywność. Odkrycia naukowe i
					neotechnologie znacznie przyspieszą nasz rozwój i wywrą olbrzymi wpływ
					na gospodarkę narodów. Nie bogactwa naturalne, ale siła wyobraźni,
					zdolności umysłowe, inicjatywa i umiejętność tworzenia nowych
					technologii, okażą się najważniejszymi czynnikami stanowiącymi o sile
					kraju. Gwałtowny przyrost informacji w ostatnich 10 latach, potrzeba
					przeszukiwania i filtrowania tego, co do nas dociera w postaci
					pisanej, a także potrzeba określenia swojego miejsca w świecie
					sprawiają, że współczesny młody obywatel naszego kraju powinien
					dysponować wiedzą oraz osobistymi narzędziami społecznymi i
					psychologicznymi dającymi mu mocne podstawy do dalszego rozwoju.
					Wzrost świadomości wśród uczniów szkół ponadgimnazjalnych na temat
					potrzeb polskiego, ale i międzynarodowego rynku pracy sprawia, że
					coraz więcej z nich chce rozwijać swoje kompetencje i umiejętności.
					Samo posiadanie informacji nie gwarantuje sukcesu. Sukces pojawia się
					wtedy, gdy z nich robimy właściwy użytek, wykorzystując przy tym
					własne kompetencje społeczne.{' '}
				</div>
				<Gallery images={IMAGES2018} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__element'>
				<h3 className='element__year'>2019</h3>
				<div className='element__text'>
					W systemie edukacji trwa reforma. Pomysłodawca obozów p. Andrzej
					Płocharski odchodzi na zasłużoną emeruturę, p. Ignacy Rejmak otrzymuje
					propozycję pracy w innej szkole, poza Lęborkiem. Nadchodzi czas zmian.
					Idea, misja i wartości, które zostały stworzone na przestrzeni lat
					niosą projekt dalej. Maleje liczba uczestników. Przychodzi czas
					decyzji, wątpliwości. Obracając się widzimy młodych ludzi, już
					dorosłych, którzy powtarzają, aby szukać szans na kontynuację tego, co
					rozpoczęte. Mówiąc o obozach, mówią o rodzinie, relacjach, cudownym
					czasie, rozmowach o nauce do późnych godzin nocnych. Mówią o tym, jak
					wiele się nauczyli, jak stali się pewnymi siebie dorosłymi. Proszą,
					aby nie kończyć tego, co rozpoczęte. Powtarzają to, co powtarzane im
					było każdego roku podczas obozu „Nie ma rzeczy niemożliwych”, „Jeśli
					tylko chcesz…możesz to osiągnąć”, „Masz w sobie siłę o odwagę, aby
					dokonywać niemożliwego”.{' '}
				</div>
				<Gallery images={IMAGES2019} margin={0} enableImageSelection={false} />
			</div>
			<div className='history__thanks'>
				<p className='thanks__p'>
					W związku z likwidacją gimnazjów program „ Wyobraźnia ważniejsza od
					wiedzy ” zostaje zamknięty. Tak kończy się jedna historia a rozpoczyna
					druga.{' '}
				</p>
			</div>
			<div className='history__thanks'>
				<div className='thanks__list'>
					<ul>
						<b>Chcielibyśmy serdecznie podziękować:</b>
						<li className='thanks__list-item'>
							<b>1.</b> Pracownikom WMFiI UG: dr Joannie Gondek, dr Barbarze
							Wolnik, prof. UG dr hab. Antoniemu Augustynowiczowi, dr Jackowi
							Gulgowskiemu, mgr Nikodemowi Ponikwickiemu, prof. UM dr hab.
							Bogusławowi Pranszke
						</li>
						<li className='thanks__list-item'>
							<b>2.</b> Nauczycielom LO III w Gdyni: mgr Marcie Mąkosa, mgr
							Elżbiecie Koziróg, dr Adamowi Dzedzej
						</li>
						<li className='thanks__list-item'>
							<b>3.</b> Studentkom WMFiI UG p. Agacie Skrzypalik oraz p. Monice
							Mańkowskiej
						</li>
						<li className='thanks__list-item'>
							<b>4.</b> mgr Ireneuszowi Mańkowskiemu z LO nr 1 w Lęborku{' '}
						</li>
						<li className='thanks__list-item'>
							<b>5.</b> mgr Wojciechowi Malickiemu z obecnej Szkoły Podstawowej
							nr 7 w Lęborku
						</li>
						<li className='thanks__list-item'>
							<b>6.</b> Nauczycielom dawnego Gimnazjum nr 1 w Lęborku: twórcy
							projektu inż. Andrzejowi Płocharskiemu, mgr Ali Mańkowskiej, mgr
							Małgorzacie Poziemskiej, mgr Mateuszowi Ziarkowskiemu, mgr Lucynie
							Racis oraz śp. mgr Sebastianowi Guderskiemu za ogromną pomoc i
							zaangażowanie w realizacji programu.
						</li>
						<li className='thanks__list-item'>
							<b>7.</b> Pani Karinie Florek oraz całemu zespołowi Ośrodka
							Wypoczynkowego eFKa Wiele za to, że przez 9 lat tworzyli nam
							niezwykłą przestrzeń do rozwoju. Dziękujemy za cudowną kuchnię i
							cudowną atmosferę.
						</li>
					</ul>
				</div>
			</div>
			<div className='history__thanks'>
				<p className='thanks__p'>
					Dziękujemy Panu Markowi Kubiakowi Prezesowi PPD „ POLTAREX ” Sp. z
					o.o., Panu Maciejowi Babij Prezesowi PPH AMG Sp. z o.o., Panu
					Dariuszowi Karaś Prezesowi MEYN Polska Sp. z o.o., firmie WIFOT Lębork
					za ogromną pomoc i wsparcie w organizacji obozów letnich i zimowych.
					<br></br>
					<br></br>
					Tak kończy się jedna historia a rozpoczyna druga. Tak rodzi się Osada
					Wiedzy.
				</p>
			</div>
		</div>
	);
};

export default HistoryContent;
