import React from 'react';

const OfferExpandedOne = () => {
	return (
		<div className='offer__section1'>
			<div className='offer__expanded'>
				<div className='expanded__title title__border'>1. Misja</div>
				<p className='expanded__text'>
					Wychodząc naprzeciw oczekiwaniom młodzieży i potrzebom rynku pracy
					stworzyliśmy Osadę Wiedzy. Diagnoza potrzeb rynku pracy jasno
					wskazuje, że umiejętności i wiedza z zakresu przedmiotów ścisłych jest
					dzisiaj niezbędna z uwagi na rozwój nowej technologii. Z drugiej
					strony młodzieży brakuje kompetencji społecznych, które są zaniedbane
					przez współczesny system edukacji. Dlatego też, opierając się na
					20-letnim doświadczeniu w pracy pedagogicznej, konsultacjach z
					nauczycielami, trenerami, realizowanych wcześniej podobnych projektach
					stworzono przestrzeń, w której kształtujemy wszystkie niezbędne
					dzisiaj kompetencje.<br></br>
					<br></br>
					Osada wiedzy to przestrzeń dla uczniów uzdolnionych z zakresu fizyki i
					matematyki, uczniów, którzy wiążą swoją przyszłość właśnie z tymi
					naukami. Ofertę poszerzyliśmy o zajęcia z programowania a także
					warsztaty rozwijające wartość własną i pewność siebie oparte na
					narzędziach coachingowych. Rozwój interpersonalny, emocjonalny i
					kompetencyjny są dla nas głównym celem. Naszą misją jest zdobywanie
					wiedzy opartej na relacji i mentoringu.<br></br>
					<br></br>
					Osada Wiedzy opiera się na pracy wykwalifikowanych nauczycieli, z
					bogatym dorobkiem zawodowym, mających uprawnienia w zakresie
					trenerskim, pracujących i koordynujących obozy naukowe od 2010r.
					Dzięki pracownikom Uniwersytetu Gdańskiego i Uniwersytetu Morskiego
					uczniowie będą mogli korzystać bezpośrednio z wiedzy naukowców.
				</p>
				<div className='expanded__cross'>
					<span className='cross__cross1'></span>
					<span className='cross__cross2'></span>
				</div>
			</div>
			<div className='offer__expanded'>
				<div className='expanded__title'>3. Programy na obozie</div>
				<p className='expanded__text'>
					Podczas obozu realizujemy programy:
					<ol>
						<li>
							Obóz naukowy dla dzieci i młodzieży - skierowany do uczniów po VI
							klasie szkoły podstawowej oraz uczniów szkół ponadpostawowych,
							realizowane zajęcia w ramach programu: matematyka, fizyka,
							programowanie, kompetencje społeczne.
						</li>
						<li>
							Obóz naukowy dla maturzystów - fizyka, skierowany do uczniów szkół
							ponadpostawowych, realizowane zajęcia w ramach programu: fizyka,
							matematyka, kompetencje społeczne.
						</li>
						<li>
							Obóz naukowy dla maturzystów - chemia, biologia, skierowany do
							uczniów szkół ponadpodstawowych, realizowane zajęcia w ramach
							programu: chemia, biologia, matematyka, kompetencje społeczne.
						</li>
					</ol>
				</p>
				<div className='expanded__cross'>
					<span className='cross__cross1'></span>
					<span className='cross__cross2'></span>
				</div>
			</div>
			<div className='offer__expanded'>
				<div className='expanded__title'>2. Uczestnicy</div>
				<p className='expanded__text'>
					Uczestnikami obozu mogą być uczniowie po VI klasie Szkoły Podstawowej.
					Wymagana jest opinia nauczyciela matematyki (w przypadku uczniów po VI
					klasie SP) oraz fizyki (po VII kl. SP), a w przypadku wybrania
					programu biologia/chemia opinia nauczycieli tych przedmiotów.
				</p>
				<div className='expanded__cross'>
					<span className='cross__cross1'></span>
					<span className='cross__cross2'></span>
				</div>
			</div>
		</div>
	);
};

export default OfferExpandedOne;
